import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Testimonial from "./testimonial"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
        autoplay: true,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
      },
    },
  ],
}

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      ind: file(relativePath: { eq: "clients/ind.jpg" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      kp: file(relativePath: { eq: "clients/kp.jpg" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      milton: file(relativePath: { eq: "clients/milton.jpg" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cbd: file(relativePath: { eq: "clients/cbd.jpg" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      westend: file(relativePath: { eq: "clients/westend.jpg" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      woolloongabba: file(relativePath: { eq: "clients/woolloongabba.jpg" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <section class="container text-align-center mg-30 card" id="testimonials">
      <div class="row">
        <div class="col-md-12">
          <h2 class="mb-10">Don't just take our word</h2>
          <p>See what we have done for clients just like you.</p>
        </div>
      </div>

      <div>
        <Slider {...settings}>
          <div>
            <Testimonial
              img={data.westend.childImageSharp.fixed}
              services="Regular chute cleaning"
              location="West End"
            />
          </div>
          <div>
            <Testimonial
              img={data.ind.childImageSharp.fixed}
              services="Regular chute cleaning"
              location="Indooroopilly"
            />
          </div>
          <div>
            <Testimonial
              img={data.kp.childImageSharp.fixed}
              services="Regular chute cleaning"
              location="Kangaroo Point"
            />
          </div>
          <div>
            <Testimonial
              img={data.milton.childImageSharp.fixed}
              services="Regular chute cleaning"
              location="Milton"
            />
          </div>
          <div>
            <Testimonial
              img={data.woolloongabba.childImageSharp.fixed}
              services="Regular chute cleaning"
              location="Woolloongabba"
            />
          </div>
          <div>
            <Testimonial
              img={data.cbd.childImageSharp.fixed}
              services="Regular chute cleaning"
              location="Brisbane CBD"
            />
          </div>
        </Slider>
      </div>
    </section>
  )
}

export default Testimonials
