import React from "react"
import Img from "gatsby-image"

import "../static/css/testimonial.css"

const Testimonial = ({ img, services, location }) => (
  <div class="single-testimonial">
    <Img fixed={img} alt={location + " " + services} className="center-block" />
    <p>{services}</p>
    <h3>{location}</h3>
  </div>
)

export default Testimonial
